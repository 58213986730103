import FLOWER1 from "../images/Flower1.jpg";
import FLOWER2 from "../images/Flower2.jpg";
import FLOWER3 from "../images/Flower3.jpg";
import FLOWER4 from "../images/Flower4.jpg";
import FLOWER5 from "../images/Flower5.jpg";
import FLOWER6 from "../images/Flower6.jpg";

const cardimages = [{
    src: FLOWER1,
    matched: false
},
{
    src: FLOWER2,
    matched: false
},
{
    src: FLOWER3,
    matched: false
},
{
    src: FLOWER4,
    matched: false
},
{
    src: FLOWER5,
    matched: false
},
{
    src: FLOWER6,
    matched: false
},
]

export default cardimages;